<template>
  <div>
    <GenericTable
      ref="lista"
      name="tipo de embalagem"
      title="Tipos de Embalagens"
      add-route="tipoEmbalagemAdicionar"
      edit-route="tipoEmbalagemEditar"
      :nofilters="true"
      :cols="['Nome', 'Descrição']"
      :cols-name="['nome', 'descricao']"
      :cols-map="i => [i.nome, i.descricao]"
      :route="tipoEmbalagemRoute"
      :permissionsToWrite="['rw_embalagem']"
      idKey="id_tipo_embalagem"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      tipoEmbalagemRoute: 'tipoEmbalagem',
    };
  },
};
</script>
